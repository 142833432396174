<template>
  <div id="content-wrapper">
    <TopNavbar :user-profile="userProfile" />
    <div v-if="!projectDataReady" class="loading">
      {{ trans('global.loading') }}
    </div>
    <div v-else id="content">
      <div id="project-topbar">
        <b-container fluid>
          <b-row>
            <b-col sm="8" lg="6" class="projectname padding-top-30">
              <p class="my-0 text-muted font-weight-light">
                {{ project.name }}
              </p>
              <h2>{{ prospect.title }}</h2>
            </b-col>
            <b-col sm="4" lg="6">
              <div class="d-flex justify-content-end align-items-end tools h-100">
                <b-button :href="newFrontendProspectLink" target="_blank" variant="primary" class="margin-right-16">
                  <i class="far fa-external-link-alt"></i> Prospekt (ny)
                </b-button>
                <!-- <b-button :href="frontendProspectLink" target="_blank" variant="primary" class="margin-right-16">
                  <i class="far fa-external-link-alt"></i> Prospekt (gammelt)
                </b-button> -->
                <b-button :to="'/projects/' + project.slug + '/sales'" variant="primary">
                  <i class="fas fa-angle-left padding-right-6"></i>{{ trans('units.back_to_project', { project: project.name }) }}
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="tabnav p-0">
              <b-nav class="nav-underlined">
                <b-nav-item :active="activeTab === 'dashboard'" @click="toggleTab('dashboard')">
                  {{ trans('global.overview') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'styletypes'" @click="toggleTab('styletypes')">
                  {{ trans('global.styletypes') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'landingpage'" @click="toggleTab('landingpage')">
                  {{ trans('global.landingpage') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'designer'" @click="toggleTab('designer')">
                  {{ trans('global.designer') }}
                </b-nav-item>
                <b-nav-item :active="activeTab === 'settings'" @click="toggleTab('settings')">
                  {{ trans('global.settings') }}
                </b-nav-item>
              </b-nav>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <ProjectSalesProspectDashboard v-if="activeTab === 'dashboard'" id="project-sales-prospect-dashboard"></ProjectSalesProspectDashboard>
      <ProjectSalesProspectStyles v-if="activeTab === 'styletypes'" id="project-sales-prospect-styles" :prospect="prospect"></ProjectSalesProspectStyles>
      <ProjectSalesProspectLandingpage v-if="activeTab === 'landingpage'" id="project-sales-prospect-landingpage" :prospect="prospect" :project="project"></ProjectSalesProspectLandingpage>
      <ProjectSalesProspectDesigner v-if="activeTab === 'designer'" id="project-sales-prospect-designer" :prospect="prospect" :project="project"></ProjectSalesProspectDesigner>
      <ProjectSalesProspectSettings v-if="activeTab === 'settings'" id="project-sales-prospect-settings" :prospect="prospect"></ProjectSalesProspectSettings>
    </div>
  </div>
</template>

<script>
  /**
   * Unit-types (hardcoded for now):
   1. Kjøkken
   2. Kjøkken/Stue
   3. Stue
   4. Soverom
   5. Bad
   6. WC
   7. Vaskerom
   8. WC/Vaskerom
   */

  import { mapState } from 'vuex'
  import TopNavbar from '../../../../components/Navigation/TopNavbar'
  import ProjectSalesProspectDashboard from '../../../../components/Views/Projects/Sales/Prospect/Dashboard'
  import ProjectSalesProspectStyles from '../../../../components/Views/Projects/Sales/Prospect/Styles'
  import ProjectSalesProspectLandingpage from '../../../../components/Views/Projects/Sales/Prospect/Landingpage'
  import ProjectSalesProspectSettings from '../../../../components/Views/Projects/Sales/Prospect/Settings'
  import ProjectSalesProspectDesigner from '../../../../components/Views/Projects/Sales/Prospect/Designer'

  export default {
    name: 'ProjectSalesProspectPage',
    components: {
      TopNavbar,
      ProjectSalesProspectDashboard,
      ProjectSalesProspectStyles,
      ProjectSalesProspectSettings,
      ProjectSalesProspectLandingpage,
      ProjectSalesProspectDesigner
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile,
        projects: state => state.projects.projects,
        configs: state => state.configs,
        prospects: state => state.prospects.prospects
      }),
      projectDataReady () {
        return this.project && this.prospects && this.prospect
      },
      project () {
        return this.projects.find(project => project.slug === this.$route.params.projectslug)
      },
      prospect () {
        return this.prospects.find(prospect => prospect.slug === this.$route.params.prospectslug && prospect.projectId === this.project._id)
      },
      activeTab () {
        return (this.configs.projectTopbarMenu !== undefined && this.configs.projectTopbarMenu.activeTab !== '') ? this.configs.projectTopbarMenu.activeTab : 'dashboard'
      },
      // frontendProspectLink () {
      //   const domain = window.location.origin.includes('localhost') ? 'http://localhost:3000' : process.env.VUE_APP_FRONTEND_URL
      //   return domain + '/eiendom/' + this.project.varycode + '/' + this.prospect.varycode
      // },
      newFrontendProspectLink () {
        const domain = window.location.origin.includes('localhost') ? 'http://localhost:3000' : 'https://hol.vary.no'
        return domain + '/prosjekter/' + this.project.varycode + '/' + this.prospect.varycode
      }
    },
    beforeMount () {
      this.toggleTab('dashboard')
      this.updateBreadcrumb(
        [
          { text: this.trans('global.projects'), to: '/projects' },
          {
            text:
              {
                store: 'projects',
                searchin: 'slug',
                searchfor: this.$route.params.projectslug
              },
            to: '/projects/' + this.$route.params.projectslug
          },
          { text: this.trans('global.sales'), to: '/projects/' + this.$route.params.projectslug + '/sales' },
          {
            text:
              {
                store: 'prospects',
                searchin: 'slug',
                searchfor: this.$route.params.prospectslug,
                parentidkey: 'projectId',
                childmode: true
              },
            to: '/projects/' + this.$route.params.projectslug + '/sales/' + this.$route.params.prospectslug
          }
        ]
      )
    },
    methods: {
      toggleTab (tab) {
        this.activetab = tab
        this.$store.commit('configs/setProjectTopbarMenu', tab)
      }
    }
  }
</script>
